/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: #0a53e4;
}

.input-container .filled {
  transform: translate(0, 6px) scale(0.8);
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 26px) scale(0.9);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #5a5a5a;
  font-size: 16px;
  line-height: 1;
  left: 16px;
  /* width: max-content; */
}

.input-container input {
  height: 48px;
  border-radius: 4px;
  /* border: none; */
  padding: 26px 16px 4px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-width: 1px;
  border-color: #d9d9d9;
  margin-top: 4px;
  /* width: max-content; */
}

.input-container input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}
