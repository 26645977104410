/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul,
  ol {
    list-style: revert;
  }
}

/* ==========================================================================
     BLOG
    ========================================================================== */
@layer components {
  .card {
    /* background-color: theme("colors.white"); */
    border-radius: theme("borderRadius.lg");
    /* padding: theme("spacing.6"); */
    box-shadow: theme("boxShadow.xl");
  }
  .blog-Title {
    @apply mb-4 font-extrabold leading-tight text-gray-900;
    font-family: "sohne", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
    font-size: 32px;
  }
  .blog-Intro {
    font-family: "source-serif-pro", "Georgia", "Cambria", "Times New Roman",
      "Times", "serif";
    font-size: 20px;
    line-height: 1.7;
    font-style: normal;
    font-weight: 400;
    color: rgb(30 41 59);
    margin-bottom: 10px;
  }
  .blog-content {
    font-size: 18px;
    line-height: 1.7;
    font-style: normal;
    font-weight: 400;
    color: rgb(29, 36, 47);
  }
  .blog-content p {
    font-family: "source-serif-pro", "Georgia", "Cambria", "Times New Roman",
      "Times", "serif";
    font-size: 18px;
    line-height: 1.7;
    font-style: normal;
    font-weight: 400;
    color: rgb(30 41 59);
  }
  /* .blog h1 {
            @apply text-3xl;
          } */
  .blog-subheader {
    font-size: 21px;
    font-family: "sohne", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
    font-weight: 600;
    font-style: normal;
    line-height: 28px;
    margin-top: 30px;
  }
  .blog-subheader2 {
    margin-top: 4px;
    font-size: 19px;
    font-family: "sohne", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    line-height: 28px;
  }
  .blog h3 {
    @apply font-semibold;
    font-size: 22px;
    padding-top: 10px;
  }

  .blog {
    font-family: "source-serif-pro", "Georgia", "Cambria", "Times New Roman",
      "Times", "serif";
    font-size: 19px;
    letter-spacing: 1px;
    color: rgb(30 41 59);
    @apply mt-6;
  }
  .blog-list {
    margin-top: 4px;
    @apply mb-4 list-disc list-inside;
  }
  .blog-conclusion {
    font-family: "source-serif-pro", "Georgia", "Cambria", "Times New Roman",
      "Times", "serif";
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
